// Collection of simple functions that you typically find in libraries like Underscore, Lodash oer similar.

export function euro (amount) {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(amount)
}

export function formatNumber (num, digits = 1) {
  return Number(num).toFixed(digits).replace('.', ',')
}

export function range (start, end) {
  return [...Array(end - start + 1).keys()].map(i => i + start)
}

// like Array.filter(), but for plain objects
export function objectFilter (obj, filterFunction) {
  return Object.fromEntries(Object.entries(obj).filter((entry) => {
    return filterFunction(entry[1], entry[0])
  }))
}

export function removeHtml (text) {
  return text.replace(/<.*?>/g, ' ')
}

export function roundPriceTo50CentsString (price: number, ceil = false) {
  const fullPrice = ceil ? Math.ceil(price * 2) / 2 : Math.round(price * 2) / 2
  return euro(fullPrice).replace(',00', '')
}

export function getRandomId () {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  return Array.from({ length: 8 }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('')
}
